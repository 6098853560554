import { green, grey, red, teal } from '@mui/material/colors'
import Logo from '../assets/logo_SaasOffice-horizontal.svg'
import LoginLogo from '../assets/logo_SaasOffice-vertical.svg'
import BgMain from '../assets/platform-SaaSOffice-Demo-13062024.jpg'
import { customBlueSaasO, customYellowSaasO } from './common'

export const lightTheme = {
  name: 'Light',
  logo: { header: Logo, login: LoginLogo },
  mainBackground: BgMain,
  palette: {
    mode: 'light',
    primary: {
      main: customBlueSaasO[800],
      light: customBlueSaasO[800],
      dark: customBlueSaasO[900],
      contrastText: '#ffffff'
    },
    secondary: {
      main: customBlueSaasO[900],
      light: customYellowSaasO[300],
      dark: customYellowSaasO[900],
      contrastText: '#ffffff'
    },
    background: {
      default: '#fcfcfc',
      paper: '#ffffff'
    },
    grey: {
      50: '#f8F8F8',
      100: '#f8f9fa',
      200: '#dee2e6',
      300: '#ced4da',
      400: '#adb5bd',
      500: '#8c9094',
      600: '#85909a',
      700: '#7f8a93',
      800: '#6c757d',
      900: '#2a2e32'
    }
  },
  header: {
    color: grey[200],
    background: '#ffffff',
    borderBottom: '1px solid',
    borderColor: grey[200],
    search: {
      color: grey[100]
    },
    indicator: {
      background: red[700]
    }
  },
  sidebar: {
    color: grey[800],
    background: '#ffffff',
    borderRight: '1px solid',
    borderColor: grey[200],
    linkActive: {
      color: customBlueSaasO[900],
      background: teal[50]
    },
    linkActiveHover: {
      color: grey[900],
      background: 'red'
    },
    linkOpen: {
      background: teal[50],
      color: customBlueSaasO[900]
    },
    linkOpenHover: {
      background: teal[50],
      color: customBlueSaasO[900]
    },
    scrollbar: {
      borderRight: '0',
      borderColor: 'rgba(0, 0, 0, 0)'
    },
    header: {
      color: '#ffffff',
      background: '#ffffff',
      brand: {
        scale: 2.5,
        size: {
          width: '150px'
        }
      }
    },
    footer: {
      color: grey[800],
      background: '#F7F7F7',
      online: {
        background: green[500]
      }
    }
  },
  chip: {
    color: '#000',
    background: customYellowSaasO[900]
  }
}
