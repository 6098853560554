import { grey } from '@mui/material/colors'
import Logo from '../assets/Logo_horizontal-white.png'
import LogoLogin from '../assets/Logo_vertical.png'
import { custom0rangeStartWay, customBlueStartWay } from './common'
import Background from '../assets/platform-SaaS-StartWay.jpg'

export const defaultTheme = {
  name: 'Startway Default',
  logo: {
    header: Logo,
    login: LogoLogin
  },
  mainBackground: Background,
  palette: {
    primary: {
      main: custom0rangeStartWay[800],
      light: custom0rangeStartWay[700],
      dark: custom0rangeStartWay[900],
      contrastText: '#ffffff'
    },
    secondary: {
      main: customBlueStartWay[900],
      light: customBlueStartWay[500],
      dark: customBlueStartWay[900],
      contrastText: '#ffffff'
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff'
    },
    grey: {
      50: '#f8F8F8',
      100: '#f8f9fa',
      200: '#dee2e6',
      300: '#ced4da',
      400: '#e9ecef',
      500: '#8c9094',
      600: '#85909a',
      700: '#7f8a93',
      800: '#6c757d',
      900: '#2a2e32'
    }
  },
  header: {
    background: '#040506',
    color: '#ffffff',
    indicator: {
      background: '#000000'
    }
  },
  sidebar: {
    color: grey[50],
    background: '#040506',
    borderRight: '0',
    borderColor: grey[200],
    linkActive: {
      color: '#FFFFFF',
      background: custom0rangeStartWay[800]
    },
    linkActiveHover: {
      color: '#FFFFFF',
      background: custom0rangeStartWay[800]
    },
    linkOpen: {
      color: '#FFFFFF',
      background: custom0rangeStartWay[800]
    },
    linkOpenHover: {
      color: '#FFFFFF',
      background: custom0rangeStartWay[800]
    },
    header: {
      color: '#ffffff',
      background: '#040506',
      brand: {
        color: '#ffffff',
        size: {
          width: '150px',
          height: '39px'
        },
        scale: 2.5,
        marginTop: '30px'
      }
    },
    footer: {
      color: '#ffffff',
      background: '#000000',
      online: {
        background: '#ffffff'
      }
    },
    badge: {
      color: '#000',
      background: '#ffffff'
    }
  },
  chip: {
    color: '#ffffff',
    background: customBlueStartWay[600]
  }
}
