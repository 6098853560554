export const customBlueSaasO = {
  50: '#d6e2e4',
  100: '#c2d4d7',
  200: '#adc5c9',
  300: '#99b7bc',
  400: '#84a9ae',
  500: '#709aa1',
  600: '#5b8c93',
  700: '#477d86',
  800: '#326F78',
  900: '#2d646c'
}

export const customYellowSaasO = {
  50: '#fffaeb',
  100: '#fff4d7',
  200: '#feefc4',
  300: '#feeab0',
  400: '#fee59c',
  500: '#fedf88',
  600: '#feda74',
  700: '#fdd561',
  800: '#fdcf4d',
  900: '#FDCA39'
}

export const customBlueStartWay = {
  50: '#f2f6fb',
  100: '#e5eef6',
  200: '#ccdded',
  300: '#b2cce4',
  400: '#99bbdc',
  500: '#7faad3',
  600: '#4c88c1',
  700: '#3378b9',
  800: '#1967b0',
  900: '#0056a7'
}

export const custom0rangeStartWay = {
  50: '#fdfaf3',
  100: '#fcf5e5',
  200: '#faebcc',
  300: '#f6e0b1',
  400: '#f4d69a',
  500: '#f0cc80',
  600: '#eab74c',
  700: '#e2a318',
  800: '#e09900',
  900: '#ad720c'
}

export const customBlueFlexo = {
  50: '#eaf0f1',
  100: '#d5e1e4',
  200: '#c0d2d6',
  300: '#acc3c9',
  400: '#97b4bb',
  500: '#82a6ad',
  600: '#6d96a0',
  700: '#598892',
  800: '#306B77',
  900: '#225864'
}
export const customYellowFlexo = {
  50: '#f9efc5',
  100: '#f9e9a7',
  200: '#f9e698',
  300: '#f9e48e',
  400: '#f9e181',
  500: '#f9df77',
  600: '#f9da5e',
  700: '#f9d852',
  800: '#f9d543',
  900: '#FAD642'
}
