import { defaultTheme } from './default.variant'
import { lightTheme } from './light.variant'
import { darkTheme } from './dark.variant'
import { defaultTheme as defaultFlexO } from './default-flexo.variant'
import { lightTheme as lightFlexO } from './light-flexo.variant'
import { darkTheme as darkFlexO } from './dark-flexo.variant'
import { defaultTheme as defaultMWPI } from './default-mwpi.variant'
import { lightTheme as lightMWPI } from './light-mwpi.variant'
import { darkTheme as darkMWPI } from './dark-mwpi.variant'

const variants = [
  defaultTheme,
  lightTheme,
  darkTheme,
  defaultFlexO,
  lightFlexO,
  darkFlexO,
  defaultMWPI,
  lightMWPI,
  darkMWPI
]

export default variants
